import dataHandle from "./encrypt";

// 数据加密
var key = process.env.VUE_APP_KEY;

/**
 * @param {} config 请求设置
 */
var encryptData = function encryptData(config) {
  console.log('dataHandle :', dataHandle);
  var needEncryptData = JSON.stringify(config.data || {});
  debugger;
  var encryptData = dataHandle.encrypt(needEncryptData, key);
  var nonce = dataHandle.createNonce();
  var timestamp = dataHandle.createTimestamp();
  var sign = dataHandle.createSign(encryptData, nonce, timestamp, key);
  console.log(encryptData);
  config.data = encryptData;
  config.headers['Content-Type'] = 'application/json';
  config.headers['zr-nonce'] = nonce;
  config.headers['zr-timestamp'] = timestamp;
  config.headers['zr-gzipped'] = true;
  config.headers['zr-encrypted'] = true;
  config.headers['zr-sign'] = sign;
  return config;
};
var decryptData = function decryptData(data) {
  return dataHandle.decrypt(data, key);
};
export default {
  encryptData: encryptData,
  decryptData: decryptData
};