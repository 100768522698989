import "core-js/modules/es6.regexp.constructor";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.number.is-integer";
import "core-js/modules/es6.regexp.replace";
/**
 * Created by PanJiaChen on 16/11/18.
 */
import * as T from "./test";
import * as P from "./pattern";

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  var valid_map = ['admin', 'editor'];
  return valid_map.indexOf(str.trim()) >= 0;
}

// 验证用户名
export var validateNickName = function validateNickName(rule, value, callback) {
  var replaceValue = value.replace(P.CN_PATTERN, 'xx');
  var err = new Error('10字符以内,不支持空格和特殊符号');
  if (replaceValue === '') {
    callback(err);
  } else if (!T.testStringLength(replaceValue, {
    min: 1,
    max: 10
  })) {
    callback(err);
  } else if (!T.testSpecial(replaceValue)) {
    callback(err);
  } else {
    callback();
  }
};

// 验证账号
export var validateAccount = function validateAccount(rule, value, callback) {
  var err = new Error('4-12位,数字和字母');
  if (value === '') {
    callback(err);
  } else if (!T.testStringLength(value, {
    min: 4,
    max: 12
  })) {
    callback(err);
  } else if (!T.testName(value)) {
    callback(err);
  } else if (!T.testSpecial(value)) {
    callback(err);
  } else {
    callback();
  }
};
export var hasEnoughLength = function hasEnoughLength(rule, value, callback) {
  if (String(value).length < 6) {
    callback(new Error('请填写至少六位'));
  } else {
    callback();
  }
};
export var hasInput = function hasInput(rule, value, callback) {
  if (String(value).trim().length > 0) {
    callback();
  } else {
    callback(new Error('请输入密码'));
  }
};
export var isRightName = function isRightName(rule, value, callback) {
  if (/^[0-9a-zA-Z]+$/.test(value) && !/\s/.test(value)) {
    callback();
  } else {
    callback(new Error('不支持空格及特殊字符'));
  }
};
export var isInt = function isInt(rule, value, callback) {
  if (value === '') {
    callback();
  } else {
    if (!Number.isInteger(+value) || /\s/.test(value)) {
      callback(new Error('请填写整数'));
    } else {
      callback();
    }
  }
};
export var validatePassword = function validatePassword(rule, value, callback) {
  var reg = new RegExp('^[A-Za-z\\d]{6,12}$');
  if (reg.test(value)) {
    callback();
  } else {
    callback(new Error('请填写6-12位字母或数字'));
  }
};
export function validPassword(str) {
  var len = String(str).trim().length;
  return len >= 6 && len <= 12;
}
export function validPassword2(value) {
  var regular = /^[a-zA-Z0-9]{6,12}$/;
  return regular.test(value);
}