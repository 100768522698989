export default {
  data: function data() {
    return {};
  },
  methods: {
    onCopySuccess: function onCopySuccess(e) {
      console.log('复制成功', e);
      this.$message({
        type: 'success',
        message: '复制成功'
      });
    },
    onCopyError: function onCopyError(e) {
      console.log('复制失败', e);
      this.$message({
        type: 'success',
        message: '复制失败(当前浏览器不支持)'
      });
    }
  }
};