import fingerprintjs2 from 'fingerprintjs2';
var fingerName = 'finger';
export default {
  load: function load() {
    fingerprintjs2.get(function (components) {
      var values = components.map(function (component) {
        return component.value;
      });
      var murmur = fingerprintjs2.x64hash128(values.join(''), 31);
      localStorage.setItem(fingerName, murmur);
    });
  },
  get: function get() {
    return localStorage.getItem(fingerName);
  }
};