var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _vm._v(" "),
      _c("breadcrumb", { staticClass: "breadcrumb-container" }),
      _vm._v(" "),
      _c("div", { staticClass: "right-menu flex" }, [
        _c("div", { staticClass: "avatar-wrapper flex flex-cc" }, [
          _c("img", {
            staticClass: "user-avatar",
            attrs: { src: _vm.myavatar, alt: "" },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "flex-row user-info" }, [
            _c("div", { staticClass: "name flex-cc" }, [
              _vm._v("用户名: " + _vm._s(_vm.nickName)),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "name flex-cc" }, [
              _vm._v("账号: " + _vm._s(_vm.name)),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "login-out", on: { click: _vm.loginOut } }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }