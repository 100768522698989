export default {
  install: function install(Vue) {
    Vue.prototype.global = {
      templateTypeObj: {
        1: '前端域名',
        2: '后端域名',
        3: '资源域名',
        4: 'H5域名'
      },
      typeNameObj: {
        1: '前端域名管理',
        2: '后端域名管理',
        3: '资源域名管理',
        4: 'H5域名管理'
      }
    };
  }
};