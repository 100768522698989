import Cookies from 'js-cookie';
import store from 'storejs';
var TokenKey = 'vue_admin_template_token';
var UserKey = 'vue_admin_template_user';
export function getToken() {
  return Cookies.get(TokenKey);
}
export function setToken(token) {
  return Cookies.set(TokenKey, token);
}
export function getUser() {
  return Cookies.get(UserKey);
}
export function setUser(data) {
  return Cookies.set(UserKey, data);
}
export function removeToken() {
  return Cookies.remove(TokenKey);
}
export function getId() {
  return Cookies.get('id');
}
export function getUserName() {
  var userInfo = store.get(UserKey) || {};
  return userInfo.username;
}