import request from '@/utils/request';
export function getOperatingLogs(params) {
  return request({
    url: '/deploy/java/operationloginfo/',
    method: 'get',
    params: params
  });
}
export function getUpdateLogs(params) {
  return request({
    url: '/deploy/java/opeationupdateinfo/',
    method: 'get',
    params: params
  });
}