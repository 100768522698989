import "core-js/modules/es6.function.name";
import { login as _login,
//  logout,
getInfo as _getInfo } from '@/api/user';
import { getToken, setToken, setUser, removeToken } from '@/utils/auth';
import { resetRouter } from '@/router';
var state = {
  token: getToken(),
  name: '',
  nickName: '',
  avatar: '',
  user: null
};
var mutations = {
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_NAME: function SET_NAME(state, username) {
    state.name = username;
  },
  SET_NICKNAME: function SET_NICKNAME(state, nickName) {
    state.nickName = nickName;
  },
  SET_AVATAR: function SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  },
  SET_USER: function SET_USER(state, user) {
    state.user = user;
  }
};
var actions = {
  // user login
  login: function login(_ref, userInfo) {
    var state = _ref.state,
      commit = _ref.commit;
    var username = userInfo.username,
      password = userInfo.password,
      googleAuth = userInfo.googleAuth,
      type = userInfo.type;
    return new Promise(function (resolve, reject) {
      _login({
        username: username.trim(),
        password: password,
        googleAuth: googleAuth,
        type: type
      }).then(function (response) {
        var _response$data = response.data,
          userInfo = _response$data.userInfo,
          token = _response$data.token;
        commit('SET_TOKEN', token);
        commit('SET_USER', userInfo);
        commit('SET_NICKNAME', userInfo.nickName);
        commit('SET_NAME', userInfo.username);
        sessionStorage.setItem('username', username);
        setToken(token);
        setUser(userInfo);
        resolve(response);
      }).catch(function (error) {
        console.error(error);
        reject(error);
      });
    });
  },
  // get user info
  getInfo: function getInfo(_ref2) {
    var commit = _ref2.commit,
      state = _ref2.state;
    return new Promise(function (resolve, reject) {
      _getInfo(state.token).then(function (response) {
        var data = response.data;
        if (!data) {
          reject('Verification failed, please Login again.');
        }
        var name = data.name,
          avatar = data.avatar;
        commit('SET_NAME', name);
        commit('SET_AVATAR', avatar);
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user logout
  logout: function logout(_ref3) {
    var commit = _ref3.commit,
      state = _ref3.state;
    return new Promise(function (resolve, reject) {
      commit('SET_TOKEN', '');
      removeToken();
      resetRouter();
      resolve();
      // logout(state.token).then(() => {
      //   commit('SET_TOKEN', '')
      //   removeToken()
      //   resetRouter()
      //   resolve()
      // }).catch(error => {
      //   reject(error)
      // })
    });
  },
  // remove token
  resetToken: function resetToken(_ref4) {
    var commit = _ref4.commit;
    return new Promise(function (resolve) {
      commit('SET_TOKEN', '');
      removeToken();
      resolve();
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};