import * as P from "./pattern";

/**
 * 验证名称6-12位
 * @param {} value
 */
export var testName = function testName(value) {
  return P.USERNAME_NO_SPECIAL_PATTERN.test(value);
};

/**
 * 验证是否有符号
 * @param {} str
 */
export function testSpecial(str) {
  var specialKey = "[`+-~!#$^&*()=|{}':;',\\[\\].<>/?~！#￥……&*（）——|{}【】‘；：”“'。，、？]‘'　@";
  for (var i = 0; i < str.length; i++) {
    if (specialKey.indexOf(str.substr(i, 1)) !== -1) {
      return false;
    }
  }
  return true;
}

/**
 * 验证表情
 * @param {} value
 */
export var testEmoji = function testEmoji(value) {
  return P.EMOJI_PATTERN.test(value);
};

/**
 * 验证空白字符
 * @param {} value
 */
export var testSpace = function testSpace(value) {
  return P.SPACE_PATTERN.test(value);
};

/**
 * 验证字符串长度
 * @param {} value
 */
export var testStringLength = function testStringLength(value) {
  var range = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
    min: 6,
    max: 12
  };
  var str = String(value);
  if (str.length < range.min || str.length > range.max) {
    return false;
  }
  return true;
};