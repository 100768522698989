import Vue from 'vue';
import Vuex from 'vuex';
import getters from "./getters";
import app from "./modules/app";
import settings from "./modules/settings";
import user from "./modules/user";
import logs from "./modules/logs";
import server from "./modules/server";
import tagsView from "./modules/tagsView";
import createPersistedState from 'vuex-persistedstate';
var dataState = createPersistedState({
  paths: ['user']
});
Vue.use(Vuex);
var store = new Vuex.Store({
  localStorage: localStorage,
  modules: {
    app: app,
    settings: settings,
    user: user,
    logs: logs,
    server: server,
    tagsView: tagsView
  },
  getters: getters,
  plugins: [dataState]
});
export default store;