import request from '@/utils/request';

/**
 * 获取操作类型列表
 * @returns {Promise}
 */
export function getOpTypeList() {
  return request({
    url: '/opLog/opTypeList',
    method: 'get'
  });
}

/**
 * 分页查询操作日志
 * @returns {Promise}
 */
export function getOpLogPage(params) {
  return request({
    url: '/opLog/page',
    method: 'get',
    params: params
  });
}

/**
 * 分页查询账户信息
 * @returns {Promise}
 */
export function getUserList(params) {
  return request({
    url: '/user/list',
    method: 'get',
    params: params
  });
}

/**
 * 修改账号状态
 * @returns {Promise}
 */
export function modifyStatus(data) {
  return request({
    url: '/user/modifyStatus',
    method: 'post',
    data: data
  });
}

/**
 * 修改账号密码
 * @returns {Promise}
 */
export function modifyPassword(data) {
  return request({
    url: '/user/modifyPassword',
    method: 'post',
    data: data
  });
}

/**
 * 获取账户详情
 * @returns {Promise}
 */
export function getUserDetail(params) {
  return request({
    url: '/user/getUserDetail',
    method: 'get',
    params: params
  });
}

/**
 * 添加账号
 * @returns {Promise}
 */
export function addUser(data) {
  return request({
    url: '/user/add',
    method: 'post',
    data: data
  });
}

/**
 * 编辑账号
 * @returns {Promise}
 */
export function editUser(data) {
  return request({
    url: '/user/edit',
    method: 'post',
    data: data
  });
}

/**
 * 编辑账号
 * @returns {Promise}
 */
export function updateMyPassword(data) {
  return request({
    url: '/user/updateMyPassword',
    method: 'post',
    data: data
  });
}
export default {
  getOpTypeList: getOpTypeList,
  getOpLogPage: getOpLogPage,
  getUserList: getUserList,
  modifyStatus: modifyStatus,
  modifyPassword: modifyPassword,
  getUserDetail: getUserDetail,
  addUser: addUser,
  editUser: editUser,
  updateMyPassword: updateMyPassword
};