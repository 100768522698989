import { getOperatingLogs as _getOperatingLogs, getUpdateLogs as _getUpdateLogs } from '@/api/logs';
var state = {
  saveNewDomainTreeData: []
};
var mutations = {
  SAVE_DOMAIN: function SAVE_DOMAIN(state, value) {
    state.saveNewDomainTreeData = value;
  }
};
var actions = {
  getOperatingLogs: function getOperatingLogs(store, data) {
    return new Promise(function (resolve, reject) {
      _getOperatingLogs(data).then(function (res) {
        resolve(res);
      }).catch(function (err) {
        reject(err);
      });
    });
  },
  getUpdateLogs: function getUpdateLogs(store, data) {
    return new Promise(function (resolve, reject) {
      _getUpdateLogs(data).then(function (res) {
        resolve(res);
      }).catch(function (err) {
        reject(err);
      });
    });
  }
};
export default {
  state: state,
  mutations: mutations,
  actions: actions
};