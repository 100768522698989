import request from '@/utils/request';
export function getComputerdetail(params) {
  return request({
    url: '/deploy/computerdetail/',
    method: 'get',
    params: params
  });
}
export function postComputerdetail(data) {
  return request({
    url: '/deploy/computerdetail/',
    method: 'post',
    data: data
  });
}
export function getAllProject(params) {
  return request({
    url: "/deploy/JenkinsView/?env=".concat(params.env_tag, "&job_name"),
    method: 'get',
    params: params
  });
}
export function getComputers(params) {
  return request({
    url: '/deploy/computers/',
    method: 'get',
    params: params
  });
}
export function setDeployToZero(params) {
  return request({
    url: '/deploy/java/setdeploytozero/',
    method: 'get',
    params: params
  });
}
export function migrateDevice(data) {
  return request({
    url: '/deploy/migratedevice/',
    method: 'post',
    data: data
  });
}
export function getFreeIp(data) {
  return request({
    url: '/deploy/getfreeip/',
    method: 'post',
    data: data
  });
}
export function computers(data) {
  return request({
    url: '/deploy/computers/',
    method: 'post',
    data: data
  });
}
export function startharbor(data) {
  return request({
    url: '/deploy/startharbor/',
    method: 'post',
    data: data
  });
}
export function getfreeip(data) {
  return request({
    url: '/deploy/getfreeip/',
    method: 'post',
    data: data
  });
}
export function buildProject(data) {
  return request({
    url: '/deploy/JenkinsView/',
    method: 'post',
    data: data
  });
}
export function checkConsul(data) {
  return request({
    url: '/deploy/JenkinsView/',
    method: 'post',
    data: data
  });
}
export function getServerInfo(params) {
  return request({
    url: '/deploy/java/getserviceinfo/',
    method: 'get',
    params: params
  });
}
export function getserviceinfo(data) {
  return request({
    url: '/deploy/java/getserviceinfo/ ',
    method: 'post',
    data: data
  });
}
export function DomainInfoOp(params) {
  return request({
    url: '/deploy/DomainInfoOp/',
    method: 'get',
    params: params
  });
}
export function DomainChange(params) {
  return request({
    url: '/deploy/DomainChange/',
    method: 'get',
    params: params
  });
}
export function DomainChangePost(data) {
  return request({
    url: '/deploy/DomainChange/',
    method: 'post',
    data: data
  });
}
export function DomainRecordOp(params) {
  return request({
    url: '/deploy/DomainRecordOp/',
    method: 'get',
    params: params
  });
}
export function DomainInfoOpP(data) {
  return request({
    url: '/deploy/DomainInfoOp/',
    method: 'post',
    data: data
  });
}
export function DomainInfoOpPost(data) {
  return request({
    url: '/deploy/DomainRecordOp/',
    method: 'post',
    data: data
  });
}
export function CreateCertiFacateP(data) {
  return request({
    url: '/deploy/CreateCertiFacate/',
    method: 'post',
    data: data
  });
}
export function CreateCertiFacateG(params) {
  return request({
    url: '/deploy/CreateCertiFacate/',
    method: 'get',
    params: params
  });
}
export function getDomainInfo(params) {
  return request({
    url: '/deploy/java/getdomaininfo/',
    method: 'get',
    params: params
  });
}
export function JstackOp(params) {
  return request({
    url: '/deploy/JstackOp/',
    method: 'get',
    params: params
  });
}
export function JstackOpPost(data) {
  return request({
    url: '/deploy/JstackOp/',
    method: 'post',
    data: data
  });
}
export function getOrganizetion(params) {
  return request({
    url: '/deploy/java/GetOrganization/',
    method: 'get',
    params: params
  });
}
export function agentServer(params) {
  return request({
    url: '/deploy/java/AgentServer/',
    method: 'get',
    params: params
  });
}
export function postAgentServer(data) {
  return request({
    url: '/deploy/java/AgentServerUpdate/',
    method: 'post',
    data: data
  });
}
export function FileDomainUpdate(data) {
  return request({
    url: '/deploy/java/FileDomainUpdate/',
    method: 'post',
    data: data
  });
}
export function getUpdateServiceList(data) {
  return request({
    url: '/deploy/java/getupdateservice/',
    method: 'post',
    data: data
  });
}
export function getStartStopServiceList(data) {
  return request({
    url: '/deploy/StartStopAllServer/',
    method: 'post',
    data: data
  });
}
export function getEnvList(params) {
  return request({
    url: '/deploy/getenvinfo/',
    method: 'get',
    params: params
  });
}
export function getOperationStatus(params) {
  return request({
    url: '/deploy/OperateStatus/',
    method: 'get',
    params: params
  });
}
export function pushResult(data) {
  return request({
    url: '/deploy/java/updateserviceinfo/',
    method: 'post',
    data: data
  });
}
export function actionNode(data) {
  return request({
    url: '/deploy/java/operatorjavadeploy/',
    method: 'post',
    data: data
  });
}
export function checkStatus(params) {
  return request({
    url: '/deploy/java/nodedataresult/',
    method: 'get',
    params: params
  });
}
export function startServer(data) {
  return request({
    url: '/deploy/java/openserver/',
    method: 'post',
    data: data
  });
}
export function closeServer(params) {
  return request({
    url: '/deploy/java/closeserver/',
    method: 'get',
    params: params
  });
}
export function confirmServer(data) {
  return request({
    url: '/deploy/java/confirmserver/',
    method: 'post',
    data: data
  });
}
export function restart(data) {
  return request({
    url: '/deploy/java/operatemanyservice/',
    method: 'post',
    data: data
  });
}
export function computerdetail(data) {
  return request({
    url: '/deploy/computerdetail/',
    method: 'post',
    data: data
  });
}
export function getServiceList(params) {
  return request({
    url: '/deploy/getservicelist/',
    method: 'get',
    params: params
  });
}
export function consulCheck(data) {
  return request({
    url: '/deploy/java/consulservice/',
    method: 'post',
    data: data
  });
}
export function getLogoutList(params) {
  return request({
    url: '/deploy/java/harborservice/',
    method: 'get',
    params: params
  });
}
export function logoutService(data) {
  return request({
    url: '/deploy/java/consulservice/',
    method: 'post',
    data: data
  });
}
export function updateVersion(data) {
  return request({
    url: '/deploy/java/singleupdate/',
    method: 'post',
    data: data
  });
}
export function transferService(data) {
  return request({
    url: '/deploy/singlemigrateservice/',
    method: 'post',
    data: data
  });
}
export function addService(data) {
  return request({
    url: '/deploy/singladdservice/',
    method: 'post',
    data: data
  });
}
export function opSingleServer(data) {
  return request({
    url: '/deploy/opsingleserver/',
    method: 'post',
    data: data
  });
}
export function postUpdateGitFile(data) {
  return request({
    url: '/deploy/java/updategitfile/',
    method: 'post',
    data: data
  });
}
export function singleupdate(data) {
  return request({
    url: '/deploy/java/singleupdate/',
    method: 'post',
    data: data
  });
}
export function redisSetKey(data) {
  return request({
    url: 'http://47.56.107.225:8080/redisSetKey',
    method: 'post',
    data: data
  });
}
export function redisGetKey(data) {
  return request({
    url: 'http://47.56.107.225:8080/redisGetKey',
    method: 'post',
    data: data
  });
}
export function KafkaProducer(data) {
  return request({
    url: 'http://47.56.107.225:8888/kafkaProducer',
    method: 'post',
    data: data
  });
}
export function kafkaConsumer(params) {
  return request({
    url: 'http://47.56.107.225:8888/kafkaConsumer',
    method: 'get',
    params: params
  });
}
export function rocketProducer(data) {
  return request({
    url: 'http://47.56.107.225:8888/rocketProducer',
    method: 'post',
    data: data
  });
}
export function rocketConsumer(params) {
  return request({
    url: 'http://47.56.107.225:8888/rocketConsumer',
    method: 'get',
    params: params
  });
}
export function domainmonitorfromdb(params) {
  return request({
    url: '/domainmonitorfromdb/',
    method: 'get',
    params: params
  });
}
export function PullCert(params) {
  return request({
    url: '/deploy/PullCert/',
    method: 'get',
    params: params
  });
}
export function domainsetproduct(data) {
  return request({
    url: '/domainsetproduct/',
    method: 'post',
    data: data
  });
}
export function getComputerDetail(data) {
  return request({
    url: '/deploy/computerdetail/',
    method: 'post',
    data: data
  });
}
export function getComputer(data) {
  return request({
    url: '/deploy/computers/',
    method: 'post',
    data: data
  });
}
export function addMoreDomainNameOfPost(data) {
  return request({
    url: '/deploy/AddManyDomain/',
    method: 'post',
    data: data
  });
}
export function addMoreRecordOfPost(data) {
  return request({
    url: '/deploy/AddRecordView/',
    method: 'post',
    data: data
  });
}
export function addPictureDomainChangeOfPost(data) {
  return request({
    url: '/deploy/java/PictureDomainChange/',
    method: 'post',
    data: data
  });
}
// 获取用户信息
export function userInfo(params) {
  return request({
    url: 'deploy/UserInfo/',
    method: 'get',
    params: params
  });
}
export function operateUserInfo(data) {
  return request({
    url: 'deploy/UserInfo/',
    method: 'post',
    data: data
  });
}
export function pictureDomain() {
  return request({
    url: 'deploy/java/PictureDomainChange/',
    method: 'get'
  });
}