import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

/* Layout */
import Layout from '@/layout';

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export var constantRoutes = [{
  path: '/login',
  name: 'login',
  component: function component() {
    return import('@/views/login/index');
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return import('@/views/404');
  },
  hidden: true
}, {
  path: '/',
  component: Layout,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    name: '域名管理后台',
    component: function component() {
      return import('@/views/dashboard/index');
    },
    meta: {
      title: '域名管理后台',
      icon: 'el-icon-menu'
    }
  }]
}, {
  path: '/nested',
  component: Layout,
  redirect: '/nested/program',
  name: '域名管理',
  meta: {
    title: '域名管理',
    icon: 'el-icon-edit-outline'
  },
  children: [{
    path: 'program',
    component: function component() {
      return import('@/views/domain/domainPlan');
    },
    // Parent router-view
    name: '域名方案管理',
    meta: {
      title: '域名方案管理'
    }
  }, {
    path: 'status',
    component: function component() {
      return import('@/views/domain/domainStatus');
    },
    name: 'DomainStatus',
    meta: {
      title: '域名状态管理',
      noCache: true
    }
  }, {
    path: 'supplier',
    component: function component() {
      return import('@/views/domain/DomainSupplier');
    },
    name: 'DomainSupplier',
    meta: {
      title: '线路商管理',
      noCache: true
    }
  }, {
    path: 'imageDomain',
    component: function component() {
      return import('@/views/domain/ImageDomain');
    },
    name: 'imageDomain',
    meta: {
      title: '图片域名管理',
      noCache: true
    }
  }, {
    path: 'template',
    component: function component() {
      return import('@/views/domain/DomainTemplate');
    },
    name: 'DomainTemplate',
    meta: {
      title: '检测模板管理',
      noCache: true
    }
  }, {
    path: 'iplist',
    component: function component() {
      return import('@/views/domain/iplist');
    },
    name: 'iplist',
    meta: {
      title: '被拦截ip列表',
      noCache: true
    }
  }, {
    path: 'group/1/:schemeId/:schemeName',
    component: function component() {
      return import('@/views/domain/domainGroup');
    },
    name: 'group',
    meta: {
      title: '前端域名管理',
      noCache: true,
      type: 1
    },
    hidden: true,
    props: true
  }, {
    path: 'group/2/:schemeId/:schemeName',
    component: function component() {
      return import('@/views/domain/domainGroup');
    },
    name: 'group',
    meta: {
      title: '后端域名管理',
      noCache: true,
      type: 2
    },
    hidden: true,
    props: true
  }, {
    path: 'group/3/:schemeId/:schemeName',
    component: function component() {
      return import('@/views/domain/domainGroup');
    },
    name: 'group',
    meta: {
      title: '资源域名管理',
      noCache: true,
      type: 3
    },
    hidden: true,
    props: true
  }, {
    path: 'group/4/:schemeId/:schemeName',
    component: function component() {
      return import('@/views/domain/domainGroup');
    },
    name: 'group',
    meta: {
      title: 'H5域名管理',
      noCache: true,
      type: 4
    },
    hidden: true,
    props: true
  }, {
    path: 'groupManage/:type/:schemeId/:domainGroupId/:schemeName/:groupName',
    component: function component() {
      return import('@/views/domain/domainGroupManage');
    },
    name: 'groupManage',
    meta: {
      title: '域名组管理',
      noCache: true
    },
    hidden: true,
    props: true
  }, {
    path: 'domainPool/:dgulpId/:type?/:schemeId?/:domainGroupId?/:schemeName?/:groupName?/:deviceName?/:levelName?/:island?/:country?/:provinceName?',
    component: function component() {
      return import('@/views/domain/domainPoolManage');
    },
    name: 'domainPool',
    meta: {
      title: '域名池',
      noCache: true
    },
    hidden: true,
    props: true
  }]
}, {
  path: '/system',
  component: Layout,
  redirect: '/system/user',
  name: '系统管理',
  meta: {
    title: '系统管理',
    icon: 'el-icon-setting'
  },
  children: [{
    path: 'self',
    component: function component() {
      return import('@/views/system/self');
    },
    // Parent router-view
    name: '密码管理',
    meta: {
      title: '密码管理'
    }
  }, {
    path: 'user',
    component: function component() {
      return import('@/views/system/user');
    },
    // Parent router-view
    name: '账号管理',
    meta: {
      title: '账号管理'
    }
  }, {
    path: 'log',
    component: function component() {
      return import('@/views/system/log');
    },
    // Parent router-view
    name: '操作日志',
    meta: {
      title: '操作日志'
    }
  }]
},
// {
// 	path: '/domain',
// 	component: Layout,
// 	redirect: 'noRedirect',
// 	name: 'domainManage',
// 	meta: {
// 		title: '域名管理',
// 		icon: 'documentation'
// 	},
// 	children: [
// 		{
// 			path: 'plan',
// 			redirect: '/plan/menu1',
// 			// component: () => import('@/views/domain/domainPlan'),
// 			name: 'plan',
// 			meta: { title: '域名方案管理', noCache: true },
// 			children: [
// 				{
// 					path: 'menu1',
// 					component: () => import('@/views/domain/domainPlan'),
// 					name: 'Menu1',
// 					meta: { title: 'Menu1-1' }

// 					// children: [
// 					// 	{
// 					// 		path: 'menu1-1',
// 					// 		component: () => import('@/views/domain/domainGroup'),
// 					// 		name: 'Menu1-1',
// 					// 		meta: { title: 'Menu1-1' }
// 					// 	}
// 					// ]
// 				},
// 				{
// 					path: 'menu2',
// 					component: () => import('@/views/domain/domainGroup'),
// 					name: 'Menu2',
// 					meta: { title: 'Menu1-2' }
// 				}
// 			]
// 		},
// 		{
// 			path: 'status',
// 			component: () => import('@/views/domain/domainStatus'),
// 			name: 'DomainStatus',
// 			meta: { title: '域名状态管理', noCache: true }
// 		},
// 		{
// 			path: 'template',
// 			component: () => import('@/views/domain/DomainTemplate'),
// 			name: 'DomainTemplate',
// 			meta: { title: '检测模板管理', noCache: true }
// 		},
// 		{
// 			path: 'iplist',
// 			component: () => import('@/views/domain/iplist'),
// 			name: 'iplist',
// 			meta: { title: '被拦截ip列表', noCache: true }
// 		}
// 	]
// },

// 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];
var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;